/* settings */
.setting-content{
    padding: 26px;
    position: relative;
    margin-top: 54px;
    background: white;
    width: 100%;
    border-radius: 8px 8px 32px 8px;
  }
  .setting-div{
    padding: 20px 25px 10px 25px;
    margin-top: 34px;
    background: white;
    border-radius: 8px 8px 32px 8px;
  }
 
.terms-text{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
.p-divider{
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin: 16px 0; 
  }
.logout-title{
    font-size: 20px;
    font-weight: 700;
    color: #0F172A;
    padding: 56px 0px 0px 28px;
    font-family: 'Inter', sans-serif; 
}
.logout-modal .ant-modal-close{
	top: 22px !important;
	color: #334155 !important;
}
.logout-modal{
  top: 200px !important;
}
.header-title{
  color: #0F172A;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.12px; 
}

#link-color a{
  color: black !important;
}
#about a{
  color: black !important;
}
#privacy a{
  color: black !important;
}
#terms a{
  color: black !important;
}
#cookie a{
  color: black !important;
}
  a{
font-family: "Inter", sans-serif;
  }