.user-library-dashboard-content {
    padding: 30px;
    margin-top: 20px;
    background: white;
    border-radius: 8px 8px 32px 8px;
}

.user-library-card{
    width: 100%;
    height: 226px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 12px 0px;
}
.user-library-card-label{
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
    color: #0F172A;
}
@media (max-width: 480px) {
  .task-status-title {
    font-size: 12
}
.user-library-card-label{
  font-size: 12px;
}
.client-name {
  font-size: 12
}
}
.user-library-icon{
    height: 64px;
    width: 64px;
    margin-bottom: 30px;
}
.change-client-div{
    display: flex;
    margin-top: 44px;
    margin-top: 33px;
}
.change-client{
    color: #830065;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer; 
}
.user-library-divider{
    width: 100%;
    height: 1px;
    background-color: #CBD5E1;
}
#folder_text a{
    color: #0F172A !important;
}
.search-content{
    margin-top: 30px;
    background: white;
    border-radius: 8px 8px 32px 8px;
    padding: 26px;
}
.search-content-main{
    margin-top: 32px;
    background: white;
    border-radius: 8px 8px 32px 8px;
    padding: 24px 16px 0px 16px;
    display: flex;
    justify-content: space-between;
}
.view{
    color: #830065;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    margin: 1px;
  }
#view-client a{
    color: #830065;
}
.header-text{
    font-size: 14px;
    font-weight: 700;
    color: #0F172A;
}
.create-file-div{
    background-color: #FBF1F9;
    margin: 26px 2px 2px 26px;
    display: flex;
    margin-left: auto; /* Pushes the div to the right */
    width: fit-content;
}
.folder-view-modal .ant-modal-close{
	top: 28px !important;
	color: #334155 !important;
}
.folder-view-modal{
  top: 200px !important;
}
.file-title {
    font-size: 24px;
    font-weight: 700;
    color: #0F172A;
    padding: 28px 0px 0px 38px;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-height: 60px;
}
.file-image{
    max-width: 100%;
    height: auto;
    max-height: calc(100% - 40px);
    width: auto;
    display: block;
    margin: auto;
    margin-bottom: 20px;
}
.case-note{
    margin-top: 40px;
    background: white;
    border-radius: 8px 8px 32px 8px;
    margin-bottom: 10px;
  }
.case-management-card{
    border-radius: 6px; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); 
    background: #fff; 
    padding: 18px 0px 4px 14px;
    border-left: 4px solid #FBF1F9;
    margin-right: 8px;
    text-align: left;
}
.case-management-title{
    font-size: 14px;
    font-weight: 700;
    color: #0F172A;
    margin-bottom: 5px;
}
.case-management-note{
    font-size: 12px;
    font-weight: 400;
    color: #0F172A;
    margin-bottom: 14px;
    word-wrap: break-word;
    margin-right: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.case-management-date{
    font-size: 12px;
    font-weight: 400;
    color: #0F172A;
    margin-top: 14px;
}
.case-management-content{
    margin-top: 5px;
    background: white;
    border-radius: 8px 8px 32px 8px;
    margin-bottom: 10px;
  }
.no-data-font{
    font-size: 14px;
    font-weight: 400;
    color: #b3b1b0
}

#add-note a {
    color: #830065;
  }    

.create-case-management-content{
    padding-top: 3px;
    position: relative;
    margin-top: 40px;
    background: white;
    width: 100%;
    border-radius: 8px 8px 32px 8px;
  }
.more-div{
    position: absolute;
    right: 4;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 0px 15px -10px black;
    border: 1px solid rgb(228, 228, 228);
    padding: 16px 146px 0px 20px;
}
.pop-text{
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
}
.details-title{
    font-size: 18px;
    font-weight: 700;
    color: #0F172A;
}
.details-note{
    font-size: 16px;
    font-weight: 400;
    color: #0F172A;
    margin-top: 20px;
}
#view a{
    color: #0F172A;
}
#view-text a{
    color: #830065;
}
.cancel-case{
    color: #830065;
    font-size: 14px;
    font-weight: 700;
    margin-right: 30px;
}
.add-file{
    font-size: 16px;
    font-weight: 700;
    color: #830065;
    cursor: pointer;
}
.add-icon{
    color: #830065 !important;
    font-size: 20px;
    margin-right: 8px;
    cursor: pointer;
}
.folder-view-modal .ant-modal-header {
    height: 80px !important;
  }

 .case-divider .ant-divider-horizontal {
	margin-top: 14px !important;
}
.c-divider {
    margin-top: 20px;
    height: 1px;
    width: 100%;
    margin-bottom: 24px;
    background-color: #E2E8F0;
  } 
.file-search-div{
    padding: 14px 22px 14px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.create-entry {
  position: absolute;
  top: 92px;
  right: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #FBF1F9;  */
  padding: 10px 15px;
  z-index: 1000; /* Ensure it stays on top */
}

.centered-content2 {
    display: flex;
    align-items: center;
}

.add-icon2 {
    margin-right: 8px;
    color: #830065 !important;
    font-size: 20px;
    cursor: pointer;
}

.bodytext-12 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #830065;
    cursor: pointer;
}
.entry-div{
    padding: 24px 0px 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.screen-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .left-side {
    flex: 0 0 40%;
    padding: 10px;
}

.right-side {
    flex: 0 0 60%;
    padding: 10px;
}
  
  .text-div {
    margin-top: 20px;
    margin-left: 6px;
  }
  
  .big-text-div {
    background-color: #fff;
    padding: 32px 20px;
    border-radius: 0px 0px 24px 0px;
  }
  .entry-title-text{
    font-size: 20;
    font-weight: 700;
  }
  .recent-text{
    margin-top: 20px;
    font-size: 12px;
    font-weight: 700;
    color: #64748B;
    margin-bottom: 10px;
  }
  .pointer-text{
    font-size: 14px;
    font-weight: 400;
    color: #0F172A;
    cursor: pointer;
  }
  .details-entry-div{
    margin-top: 40px;
    background: white;
    border-radius: 8px 8px 32px 8px;
    padding: 26px;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word; 
}
.last-row td {
    border-bottom: none !important;
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.229);
    z-index: 9999;
  }
  .modal-div{
    padding: 50px 25px 20px 25px; 
    font-size: 16px;
    margin-top: 20px; 
    font-weight: bold
  }
  .modal-button-div{
    display: flex;
    justify-content: right;
    padding: 0 22px 22px 22px
  }
  @media screen and (max-width: 900px) {
    .add-file{
      font-size: 14px;
      font-weight: 700;
      color: #830065;
      cursor: pointer;
      margin-right: 8px;
    }
}
  @media (max-width: 440px) {
    .add-file{
      font-size: 14px;
      font-weight: 700;
      color: #830065;
      cursor: pointer;
    }
    .no-data-font{
      font-size: 14px;
      font-weight: 400;
      color: #b3b1b0
  }
  }

  .add-plus-icon{
    color: #830065 !important;
    font-size: 24px !important;
    margin-right: 7px !important;
}
  @media (max-width: 440px) {
    .add-plus-icon{
        color: #830065 !important;
        font-size: 16px !important;
        margin-right: 7px !important;
    }
  }
  .add-icon3{
    color: #830065 !important;
    font-size: 24px !important;
    margin-right: 8px;
    cursor: pointer;
}
  @media screen and (max-width: 500px) {
    .add-file{
        font-size: 12px;
        font-weight: 700;
        color: #830065;
        cursor: pointer;
    }
    .add-icon3{
        color: #830065 !important;
        font-size: 16px !important;
        margin-right: 8px;
        cursor: pointer;
    }
  }


@media only screen and (max-width: 768px) {
    .screen-container {
        flex-direction: column;
    }

    .left-side, .right-side {
        padding: 10px;
    }

    .case-management-card {
        font-size: 14px;
    }

    .recent-text {
        font-size: 14px;
    }

    .big-text-div {
        font-size: 14px;
    }
}

@media only screen and (max-width: 480px) {
    .screen-container {
        padding: 10px;
    }

    .apt-calender {
        margin-right: 0;
    }

    .case-management-card {
        padding: 10px;
    }

    .big-text-div {
        padding: 10px;
    }

    .recent-text {
        font-size: 12px;
    }

    .entry-title-text {
        font-size: 18px;
    }
}

@media only screen and (max-width: 600px) {
  .screen-container {
      margin-top: 50px;
  }
  .create-entry{
    top: 106px;
  }
}
.no-border-bottom tr td,
.no-border-bottom tr th {
  border-bottom: none;
}


.custom-table-container {
  border-radius: 0 0 20px 0; /* Only the bottom-right corner */
  overflow: hidden; /* Keep content within the rounded border */
}
.MuiTableCell-root{
  border-bottom: 1px solid #DDDDDD66 !important;
}