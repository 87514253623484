.forgot-section{
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
    align-items: center;
	margin-top: 245px;
}
@media screen and (max-width: 768px) {
    .forgot-section {
        margin-top: 85px;
    }
}

.centered-content-right1 {
    text-align: center;
    max-width: 430px;
}
.forgot-centered-content {
	text-align: center;
	background: rgb(248 240 245);
}
.forgot-headline-1 {
	font-size: 36;
	font-weight: 700;
	color: #0f172a;
	margin: 0;
}
.forgot-hi-there {
	font-size: 18;
	font-weight: 400;
	color: #475569;
	margin: 0;
}
.forgot-back {
	font-size: 16;
	font-weight: 400;
	color: #0F172A;
	margin-left: 6px;
	text-align: left;
	margin-bottom: 32px;
}
.forgot-headline-2 {
	font-size: 24;
	font-weight: 700;
	color: #0f172a;
	text-align: center;
	margin: auto;
	padding-bottom: 10px;
} 
.disable-label .ant-form-item-label label{
	font-size: 16px;
	font-weight: 700;
	color: #9da4ad;
}

.input-error{
	border: 1px solid #DC2626 !important;
	color: #DC2626 !important;
}
@media (max-width: 450px) {
	.forgot-headline-1{
		font-size: 20px;
	} 
	.forgot-hi-there{
		font-size: 16px;
	}
}