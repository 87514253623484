.completed-div {
    background-color: #006845; 
    color: white; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 30;
    width: 94;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12;
  }
  .ongoing-div {
    background-color: #FEF3C7; 
    color: #FBB03F; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 30;
    width: 72;
    border-radius: 16px;
    font-weight: 400;
    font-size: 12;
  }
  .pending-div {
    background-color: #CBD5E1; 
    color: #64748B; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 30;
    width: 94;
    border-radius: 16px;
    font-weight: 400;
    font-size: 12;
  }
  .last-row td {
    border-bottom: none !important;
  }

  .ant-form-vertical .ant-form-item-row {
    margin-top: 17px !important;
}
.bold-label .sub-text {
    font-size: 11px; 
  }
  .view-all-task{
    font-size: 14px;
    font-weight: 700;
    color: #830065;
    cursor: pointer;
  }

.cell-style {
    height: 22px;
    width: 22px;
    color: #334155;
}
.divider-t {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 20px 0;
}
.flex-end-mt-20 {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}
.text-left-red {
    color: #DC2626;
    text-align: left;
}
.flex-start-center {
    display: flex;
    justify-items: center;
    justify-content: flex-start;
}
.delete-img{
    height: 24px;
    cursor: pointer;
    margin-left: 12px;
}
.custom-padding-font {
    padding: 50px 40px 20px 40px;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 700;
}
.l-divider {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 20px 0;
}
.custom-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 22px 22px 22px;
}
.custom-text {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
}
.custom-icon {
    height: 22px;
    width: 22px;
    color: #334155;
}
.ho-divider {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 20px 0;
}
.f-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.container-d {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.image-q {
    width: 88px;
    height: 87px;
}
.text-no {
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 400;
    color: #475569;
    margin-top: 10px;
    margin-bottom: 10px;
}
.container-u {
    display: flex;
    justify-content: space-between;
}
.cancel-button {
    background: #ffffff !important;
    border-color: #ffffff !important;
    height: 56px !important;
    width: 121px !important;
    color: #830065 !important;
    font-weight: bold !important;
    font-size: 14px !important;
}
.update-button {
    background: #830065 !important;
    border-color: #830065 !important;
    border-radius: 8px !important;
    height: 56px !important;
    width: 121px !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 14px !important;
}
.delete-task-cancel {
    height: 56px !important;
    width: 121px !important;
    background: #ffffff !important;
    border-color: #ffffff !important;
    border-radius: 8px !important;
    color: #830065 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}
.delete-task-button {
    height: 56px !important;
    width: 131px !important;
    background: #830065 !important;
    border-color: #830065 !important;
    border-radius: 8px !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}
.cancel-status-btn {
    background: #ffffff !important;
    border-color: #ffffff !important;
    height: 56px !important;
    width: 121px !important;
    color: #830065 !important;
    font-weight: bold !important;
    font-size: 14px !important;
}
.add-status-btn {
    background: #830065 !important;
    border-color: #830065 !important;
    border-radius: 8px !important;
    height: 56px !important;
    width: 121px !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 14px !important;
}
#view-task a{
    color: #830065;
}

.apt-calender .react-calendar{
    padding: 30px;
    border: none !important;
    width: 100%;
    border-radius: 0px 0px 24px 0px !important;
  }

  .apt-calender .react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--neighboringMonth){
    font-size: 14px !important;
    font-weight: 400px !important;
    color: #0F172A;
    font-family: 'Inter', sans-serif !important;
  }
  .apt-calender .react-calendar__tile--now {
    background: #ffffff ;
    font-weight: 400;
    font-weight: bold;
  }
  
  .react-calendar__navigation__label{
    pointer-events:none;
  }
  .react-calendar__month-view__days{
    padding: 8px 10px 8px 20px ;
    margin-left: 5px;
  }
  .apt-calender .react-calendar button{
    margin: 0px 1rem;
  }
  .apt-calender .react-calendar__month-view__weekdays__weekday{
  color: #64748B;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  font-family: 'Inter', sans-serif !important;
 
}

.react-calendar__month-view__weekdays{
  color: #64748B;
  padding:0px 20px !important;
  margin-left: 17px !important
}
.react-calendar__month-view__weekdays__weekday{
  flex: 0 0 13.5% !important;
  
}
.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none !important;
}
@media screen and  (max-width : 1800px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 14% !important;
  }
 
} 
@media screen and  (max-width : 1650px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 15% !important;
  }

}
@media screen and  (max-width : 1550px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 14% !important;
    padding: 0px;
  }
  .react-calendar__tile{
    flex: 0 0 14% !important;
    border-radius: 50% !important;
  }

 .apt-calender .react-calendar button{
  margin: 0px;
}
}
@media screen and  (max-width : 1390px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 14% !important;
    padding: 0px;
  }
  .react-calendar__tile{
    flex: 0 0 14% !important;
    border-radius: 50% !important;
  }
 .apt-calender .react-calendar button{
  margin: 0px;
}

}
@media screen and  (max-width : 1250px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  }
  
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 13% !important;
    padding: 0px !important;
  }
  .react-calendar__tile{
    flex: 0 0 13% !important;
    border-radius: 50% !important;
  }
 .apt-calender .react-calendar button{
  margin: 0px;
}
.apt-calender .react-calendar{
  padding: 5px !important;
}}

.search-content-task{
  margin-top: 30px;
  background: white;
  border-radius: 8px 8px 0px 8px;
  padding: 26px;
}

.last-row td:last-child {
  border-bottom-right-radius: 32px; 
  border-top-right-radius: 32px;
  overflow: hidden;
}
.add-file{
  font-size: 16px;
  font-weight: 700;
  color: #830065;
  cursor: pointer;
}
.add-icon3{
  color: #830065 !important;
  font-size: 24px !important;
  margin-right: 8px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .add-file{
      font-size: 12px;
      font-weight: 700;
      color: #830065;
      cursor: pointer;
  }
  .add-icon3{
      color: #830065 !important;
      font-size: 16px !important;
      margin-right: 8px;
      cursor: pointer;
  }
}