.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 250px);
    padding-right: 43px;
    padding-bottom: 20px;
}
.header-right-side{
    display: flex;
    align-items: center;
}
.profile-name{
    margin-top: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #0F172A;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    box-sizing: border-box;
}

.profile-label .ant-form-item-label label {
	color:  #334155;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
.disable-profile-label .ant-form-item-label label{
	font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
	color: #9da4ad;
}
.profile-header{
	font-weight: 700;
	color: #0f172a;
	text-align: left;
	padding: 25px 0px 0px 34px;
    font-size: 24px;
}
.ant-avatar.ant-avatar-icon > .anticon{
    margin: 5px;
}
.profile-modal .ant-modal-close{
	top: 25px !important;
	color: #334155 !important;
}
.image-modal .ant-modal-close{
	top: 25px !important;
	color: #334155 !important;
}
.profile-modal{
    top: 80px !important;
}
.image-modal{
    top: 250px !important;
}
.profile-divider .ant-divider-horizontal {
    margin: 12px 0px 6px 0px !important;
}
.ant-upload.ant-upload-select-picture-card {
    width: 156px !important;
    height: 156px !important;
    margin: 40px 8px 8px 20px !important;
    border-radius: 136px!important;
    border: 1px solid rgba(131, 0, 100, 0.13)!important;
}

.upload-title{
    font-size: 14px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #830065;
}
.ant-avatar{
    background: white !important;
}

.ant-upload.ant-upload-select-picture-card {
    width: 156px !important;
    height: 156px !important;
    margin: 40px 8px 8px 20px !important;
    border-radius: 136px!important;
    border: 1px solid rgba(131, 0, 100, 0.13)!important;
}

.upload-title{
    font-size: 14px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #830065;
}
.ant-avatar{
    background: white !important;
}
.spin-placement{
    display: flex;
    justify-content: center;
    margin-top: 20px
}
.spin-placement-image{
    display: flex;
    justify-content: center;
    margin-top: 0px
}
.toast-div{
    padding: 8px 16px 8px 8px;
    border-radius: 8px;
    position: fixed;
    bottom: 25px;
    background: white;
    display: flex;
    box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.15);
}
.check-img{
    height: 20px;
    width: 20px;
    margin-right: 8px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description{
    margin-left: 34px !important; 
}
.ant-notification-notice{
    border-radius: 8px !important;
    box-shadow: 0px 6px 15px -3px rgba(0, 0, 0, 0.15) !important;
    padding: 11px 16px 11px 8px !important;
    width: 100% !important;
}
.ant-notification-notice-close {
    color: white !important;
}
.ant-notification{
    transform: translateX(-25%) !important; 
}

.avatar-uploader .ant-upload {
    border-radius: 50% !important;
}
.ant-popover-inner-content {
    padding: 12px 20px 4px 16px !important;
}
.ant-popover-inner{
    border-radius: 8px !important;
} 
.popover-div{
    position: absolute;
    bottom: 10px;
    left: 131px;
    height: 51px;
    width: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: 50%;
    background-color: #830065;
    padding: 5px;
    cursor: pointer;
}

.profile-image-menu {
    position: absolute;
    bottom: -60px;
    left: 190px;
    z-index: 100;
    border-radius: 8px;
    background-color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 0px 15px -10px black;
    border: 1px solid rgb(228, 228, 228);
}

.more-icon{
    height: 26px;
    width: 26px;
    color: white;
}
.menu-text{
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
}
.ant-input-affix-wrapper.specific-input {
    padding: 1px !important;
    border-radius: 8px !important;
}
.ant-tag .ant-tag-close-icon {
    color: #c180b2 !important;
}
.hobbies-title{
    color:  #334155;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 6px;
}
.tag-div{
    border: 1px solid #CBD5E1;
    border-radius: 7px;
    padding: 20px 20px 16px 20px;
    float: left;
    width: 100%;
}
.custom-tag {
    color: #c180b2;
    background: #f6ecf3;
    border: none;
    border-radius: 50px;
    padding: 3px 7px;
    margin-bottom: 5px;
  }

.header-right-side img,
.header-right-side .profile-name,
.header-right-side .spin-placement-image,
.header-right-side .avatar {
    margin-right: 10px; /* Adjust the spacing as needed */
}

.header-right-side .spin-placement-image {
    display: flex;
    align-items: center;
}
.mood-text{
    font-size: 16px;
    font-weight: 400;
    color: #830065;
    margin-right: 16px;
    cursor: pointer;
}
.modal-header {
    text-align: center;
    padding: 36px 24px 15px;
}

.mood-modal-header {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
    padding-top: 55px;
}

.header-divider {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 20px 0;
}
#mood-modal .ant-modal-header {
    border-bottom: none !important;
}
#mood-modal .ant-modal-footer {
    border-top: none !important;
}

.header-divider {
    width: 100%;
    height: 1px;
    background-color: #E0E0E0;
    margin: 20px 0;
}

.mood-selection {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
}

.mood-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;
    padding-top: 20px;
    border-radius: 8px;
    transition: border-color 0.3s ease;
    max-width: 391px; 
    width: 120px;
    text-align: center; 
    background-color: #FBF1F9; 
    margin: 10px 0 10px 0;
}

.mood-option img {
    width: 44px;
    height: 44px;
    margin-bottom: 5px;
}

.mood-option.selected {
    border-color: #830065;
}

.mood-option span {
    font-size: 14px;
    color: #333;
    word-wrap: break-word;
}

.modal-footer {
    display: flex;
    justify-content: end;
    margin-top: 20px;
}
.mood-title{
    font-size: 16px;
    font-weight: 400;
    color: #830065;
}
.custom-button {
    background: #830065 !important;
    border-color: #830065 !important;
    border-radius: 8px !important;
    height: 56px !important;
    width: 120px !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}
.mood-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: auto;
}
.mood-update-container {
    background-color: white;
    padding: 6px 10px 6px 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    position: absolute; 
    top: 30px; 
    left: -210;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mood-update-text {
    font-size: 16px;
    color: #830065;
    margin-right: 10px;
    cursor: pointer;
}

.close-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #830065;
}

.close-button:hover {
    color: red;
}
.update-mood-div {
    text-align: center;
    margin-left: -120px;
}
.update-button-div {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 20px;
}
.notification-head{
    width: 100%;
    display: flex;
    padding: 15px 15px 0px 15px;
    justify-content: space-between;
}
.notification-title{
    color: #2C3659;
    font-size: 16px;
    font-weight: 700;
}
.markRead{
    color: #830065;
    font-size: 12px;
    font-weight:600;
    cursor: pointer;
}


  .notification-modal{
    position: absolute !important;
    max-width: 100%;
    right: 70 !important;
    top:80 !important;
    border-radius: 20px !important; 
    max-width: 373px !important;
    max-height: 450px !important;
    z-index: 998;

  }
  .ant-modal-content{
    overflow: hidden !important;
    border-radius: 18px !important;

  }

  .notificationContainer{
    padding: 5px 10px 2px 20px;
    height: 450px;
    border-radius: 18px !important;

}

  .notify{
    display: flex;
    padding: 5px;
    background-color: #F3F3F3;
    border-radius: 8px;
    cursor: pointer;
    word-break: break-word;
  }
  .notify2{
    display: flex;
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
    word-break: break-word;
  }
  .notification-title2{
    color:#192252;
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
    width:278px;
    padding-right: 10px;
  }
  .notification-time{
    color: #424F7B;
    font-size: 10px;
    font-weight: 400;
    padding: 3px 0;
    text-transform: capitalize;

  }
.notification-body{
    color:#717171;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 0;

}

.editerroricon{
    height: 24px !important;
    width: 24px !important;
    color:#FBB03F
}
.editerroricon2{
    height: 24px !important;
    width: 24px !important;
    color:#94A3B8
}
.ant-badge-dot {
    position: absolute;
    top:7; 
    left: 14;
    transform: translate(50%, -50%); 
  }
.notification-modal::after {
    content: "" !important;
    position: absolute !important;
    bottom: 100% !important;
    left: 65% !important;
    margin-top: 40px !important;
    margin-left: -12px !important;
    border-width: 14px !important;
    border-style: solid !important;
    z-index: 999 !important;
    border-color: transparent transparent rgb(240, 236, 236) transparent !important;
  }
  .ant-modal-body{
    border-radius: 20px !important;
  }


  .emptyNotification{
    width:373px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divider{
    margin: 8px 0px !important;
    padding: 0px !important;
  }
  .ant-spin-spinning{
    position: none !important;
    margin: 0px !important;
  
  }
 
  .ant-modal-content{
    border-radius: 18px !important;
  }

/* Tablet styles */
@media screen and (max-width: 1280px) {
    .header {
        width: calc(100% - 200px);
        padding-right: 30px;
        padding-bottom: 15px;
    }
    .profile-name {
        margin-top: 8px;
        font-size: 13px;
        margin-right: 6px;
        width: 70px;
    }
    .profile-header {
        font-size: 22px;
        padding: 20px 0 0 20px;
    }
    .upload-title {
        font-size: 13px;
    }
    .mood-option {
        padding-top: 15px;
        width: 100px;
    }
    .mood-option img {
        width: 40px;
        height: 40px;
    }
    .modal-header {
        padding: 30px 20px 10px;
    }
}

/* Mobile styles */
@media screen and (max-width: 900px) {
    .header-right-side {
        justify-content: flex-end;
        margin-top: 10px;
        margin-right: -40px;
    }
}
@media screen and (max-width: 600px) {
    .header {
        display: block;
        width: 100%;
        padding: 10px 15px;
    }
    .mood-update-text {
        font-size: 12px;
        color: #830065;
        margin-right: 10px;
        cursor: pointer;
    }
    .mood-update-container {
        padding: 3px;
    }
    .profile-name {
        margin-top: 5px;
        font-size: 12px;
        margin-right: 4px;
        width: auto;
        text-align: left;
    }
    .profile-header {
        font-size: 14px;
        padding: 15px 0 0 15px;
    }
    .upload-title {
        font-size: 12px;
        text-align: center;
    }
    .mood-option {
        padding-top: 10px;
        width: 80px;
    }
    .mood-option img {
        width: 35px;
        height: 35px;
    }
    .modal-header {
        padding: 20px 15px 5px;
    }
    .header-right-side img,
    .header-right-side .profile-name,
    .header-right-side .spin-placement-image,
    .header-right-side .avatar {
        margin-right: 5px;
    }
    .mood-update-container {
        left: 0;
        transform: translateX(-50%);
    }
    .notification-modal {
        max-width: 100%;
        right: 10px !important;
        top: 10px !important;
    }
    .notificationContainer {
        padding: 10px;
    }
    .mood-text {
        font-size: 12px;
    }
}

.page_header{
    background-color: rgb(248, 240, 245);
    display: flex; 
    justify-content: space-between; 
    position: fixed; 
    z-index: 49;
    padding: 27px 0px 20px 0px;
}

@media screen and (min-width: 991px) {
    .page_header{
        width: calc(100vw - 384px);
        
    }
}

@media screen and (max-width: 991px) {
    .page_header{
        width:100vw;
        padding: 26px 50px 0px 50px;
    }
}

  .page-header-back-icon {
    height: 24px;
    width: 24px;
  }
  
  .page-header-title {
    color: #0F172A;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }
  
  /* Tablet view */
  @media (max-width: 768px) {
    .page-header-back-icon {
      height: 20px;
      width: 20px;
    }
  
    .page-header-title {
        color: #0F172A;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
    }
  }
  
  /* Mobile view */
  @media (max-width: 480px) {
    .page-header-back-icon {
      height: 16px;
      width: 16px;
    }
  
    .page-header-title {
        color: #2a150f;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        margin: 0;
        white-space: nowrap;
        margin-right: 10px;

    }
  }
  .image-style{
    height: 44px !important;
    width: 44px !important;
  }
  .bell-icon {
    height: 28px;
    width: 28px;
    position: relative;
}
.mood-image{
    height: 28px;
    width: 28px;
  }
  @media (max-width: 480px) {
    .image-style{
        height: 30px !important;
        width: 30px !important;
      }
      .mood-image{
        height: 24px;
        width: 24px;
      }
      .bell-icon {
        height: 18px;
        width: 18px;
        position: relative;
        top: -3px;
    }
  }
