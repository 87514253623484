.sider{
    text-align: left;
    padding: 32px 29px 32px 32px;
}
.menu-item{
    font-size: 18px;
    color: #94A3B8;
    font-weight: 700;
}
.custom-content {
    padding: 0px 48px 48px 48px;
    height: 100vh;
    overflow: auto;
}
.page_content{
  padding-top: 87px;
}
.menu-icon{
    height: 24px;
    width: 24px;
    margin-bottom: 7px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff !important;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 22px!important;
  }
.ant-menu-item-selected {
    color: #830065 !important; 
}

/* profile */
.profile-dashboard-content{
    margin-top: 64px !important;
    background: white;
    position: relative;
    width: 100%;
    padding: 0 !important;
  }
  .profile-div {  
    background-color: #83006526;
    height: 20%;
    width: 100%;
  }
  
  .image-uploader {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
   
  }
  .profile-form-div {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 400px;
    transform: translateX(-50%);
    
  }
  .ant-input {
    border-radius: 8px !important; 
	  padding-left: 8px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-right: 12px !important;
      
  }
/* profile */
.case-form-div{
  width: 400px;
  margin: 0 auto;
}
.headline-3{
  font-size:14px;
  font-weight: 700;
  color: #64748B;
  margin-left: 8px;
  margin-bottom: 15px;
}
.ant-input {
  border-radius: 8px !important; 
	padding-left: 8px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-right: 12px !important;
}

  .case-number{
    font-size: 14px;
    font-weight: 700;
    color: #0F172A;
    margin-bottom: 24px;
  }
  .client-name{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
  }
  .status{
    font-size: 12px;
    font-weight: 700;
    color: white;
  }
  .case-card{
    border-radius: 6px; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); 
    background: #fff; 
    padding: 22px;
    border-left: 4px solid #FBF1F9;
    margin-right: 8px;
    text-align: center;
  }
  /* case view */
  /* appointment view */

.h-divider {
  width: 100%;
  height: 1px;
  background-color: #CBD5E1;
  margin: 16px 0; 
}
.appoint-title{
  font-size: 16px;
  font-weight: 700;
  color: #0F172A
}
.appoint-client-name{
  font-size: 14px;
  font-weight: 400;
  color: #64748B
}
.appoint-date{
  font-size: 14px;
  font-weight: 400;
  color: #0F172A
}
.appoint-added-by{
  font-size: 14px;
  font-weight: 400;
  color: #000000
}
.appoint-added-div{
  display: flex;
  justify-content: space-between;
}
.appoint-view{
  font-size: 14px;
  font-weight: 700;
  color: #830065
}
.upcoming-appoint{
  font-size: 12px;
  font-weight: 700;
  color: #0F172A;
  margin-bottom: 26px;
}

.add-appoint-div{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.appoint-card{
  border-radius: 6px; 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); 
  background: #fff; 
  padding: 16px;
  border-left: 4px solid #FBF1F9;
  margin-right: 8px;
}
.custom-picker input {
  visibility: hidden;
  height: 0;
  padding: 0;
  width: 0;
  position: absolute;
}
.case-role-div{
  background: #fff;
  width: 100%;
  margin-top: 84px;
  padding: 16px 16px 58px 20px;
}
.input-error{
	border: 1px solid #DC2626 !important;
	color: #DC2626 !important;
}
.input-error input {
  color: red;
}
.case-error-msg2{
	color: #DC2626;
	margin-top: -4px !important;
	text-align: left;
  font-size: 12px;
  font-weight: 400;
}
.case-error-msg{
	color: #DC2626;
	margin-top: -16px !important;
	text-align: left;
  font-size: 12px;
  font-weight: 400;
}
.current-error-msg{
	color: #DC2626;
	text-align: left;
  font-size: 12px;
  font-weight: 400;
}
span.ant-input-password.input-error #password.ant-input {
	color: #DC2626 !important;
  }

.change-password-text{
  font-size: 12px;
  font-weight: 400;
  color: #64748B;
  font-family: 'Inter', sans-serif;
  padding: 24px;
} 
.change-password-content{
    position: relative;
    margin-top: 40px;
    background: white;
    width: 100%;
    border-radius: 8px 8px 32px 8px;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger{
  background:rgb(248, 240, 245) !important;
  color: #830065 !important;
}
.ant-layout-sider-zero-width-trigger{
  top: 38px !important;
  font-size: 26px !important;
}
.custom-trigger-button {
  padding: 10px;
  background: rgb(251, 241, 249) none repeat scroll 0% 0%;
  position: fixed;
  z-index: 150;
  top: 31px;
  left: 289px;
  transition: left 0.3s ease;
}
.custom-trigger-button-collapsed {
  padding: 10px;
  background: rgb(251, 241, 249) none repeat scroll 0% 0%;
  position: fixed;
  z-index: 150;
  top: 31px;
  left: 0px; 
  transition: left 0.3s ease;
}
@media screen and (min-width: 300px) and (max-width: 990px) {
  .custom-trigger-button-collapsed {
      top: 26px;
  }
  .page_content{
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 900px) {
  .custom-content {
    padding: 0px;
}
}