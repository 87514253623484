/*Customizable Area Start*/
.centered-content-login {
	text-align: center;
	background: rgba(251,241,249,255);
}
.centered-content-right1 {
	text-align: center;
	max-width: 430px;
}

.headline-1 {
	font-size: 36px;
	font-weight: 700;
	color: #0f172a;
	margin: 0;
}
.headline-2-login {
	font-family: 'Inter', sans-serif; 
	font-size: 24px;
	font-weight: 700;
	color: #0f172a;
	margin: 0 0 32px 0;
	/* height: 32px; */

	
} 
.feature-content{
	font-size: 18px;
	font-weight: 400;
	color: #475569;
	margin: 0;
}
.bodytext-2 {
	font-size: 18;
	font-weight: 700;
	color: #475569;
	margin: 0;
}
.ant-input {
	border-radius: 8px !important;
	padding-left: 8px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-right: 12px !important;
}
.ant-spin-spinning {
    position: fixed !important;
    display: block !important;
    opacity: 1 !important;
    margin-left: 330px !important;
   margin-bottom:30px;
    /* align-content: flex-start; */
}
.ant-input-password {
	border-radius: 8px !important;
	padding-left: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-right: 12px !important;
}
.bold-label {
	font-size: 16px;
	font-weight: 700;
	color: #334155;
}
.bold-labels {
	font-size: 16px;
	font-weight: 700;
	color: #334155;
}
.ant-form-item-label > label {
	width: 100%;
	height: 20px;
}
.login-error-msg{
	color: #DC2626;
	
	text-align: left;
}
.input-error{
	border: 1px solid #DC2626 !important;
	color: #DC2626 !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none !important;
}
.otp-error-msg{
    color: #DC2626;
    text-align: left;
    margin-left: 40px;
}
/*password validation issue*/
span.ant-input-password.input-error #password.ant-input {
	color: #DC2626 !important;
  }
.ant-input-affix-wrapper.specific-input2 {
    border-radius: 8px !important;
    padding: 1px !important;
}
.ant-input-password-icon.anticon {
    padding: 10px !important;
}
.ant-checkbox-inner{
	border-radius: 5px !important ;
	height: 20px !important;
	width: 20px !important;
	border: 1px solid  #0F172A !important;
}

@media (max-width: 450px) {
	.headline-1{
		font-size: 20px;
	} 
}
/*Customizable Area End*/
