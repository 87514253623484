.dashboard-content {
  padding: 48px;
  margin-top: 62px;
  background: white;
}

.case-view-role-div{
  background: #fff;
  width: 100%;
  margin-top: 42px;
  padding: 16px 16px 58px 20px;
  border-radius: 8px 8px 32px 8px;
}
.case-info-div {
  border-radius: 8px 8px 32px 8px;
  background: #fff;
  width: 100%;
  margin-top: 24px;
  padding: 24px;
}

/* cases */
.create-case-box{
  position: absolute;
  top: 90;
  right: 40;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.add-bodytext-1{
  font-size: 14px;
  font-weight: 700;
  color: #830065;
  padding-left: 6px;
  margin: 0;
}
.create-case-content{
  padding: 48px;
  position: relative;
  margin-top: 40px;
  background: white;
  width: 100%;
}

.headline-3{
  font-size:14px;
  font-weight: 700;
  color: #64748B;
  margin-left: 8px;
  margin-bottom: 15px;
}
.ant-input {
  border-radius: 8px !important;
  padding-left: 8px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-right: 12px !important;
}
.case-title{
  color:#0F172A;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
}
.ant-table-thead .ant-table-cell {
  border-bottom: 1px solid #E2E8F0 !important;
  background:  #F1F5F9 !important;
}
.mood-content {
  margin-top: 22px;
  background: white;
  border-radius: 8px 8px 32px 8px;
  margin-bottom: 10px;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%; 
  max-width: 100%;
  box-sizing: border-box;
}

.mood-list {
display: flex;
flex-wrap: wrap;
width: 100%;
}
.case-search-div{
  padding: 25px 24px 25px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-text{
  color: #830065;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700; 
}
.unique-ref-text{
color: #334155;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-weight: 500; 
}
.case-full-name{
color: #000;
font-family: 'Inter', sans-serif;
font-size: 14px;
font-weight: 400; 
margin-top: 2px;
}
.custom-table {
border-bottom-left-radius: 8px !important; 
border-bottom-right-radius: 32px !important;
overflow: hidden;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td{
border-right: 1px solid #ffffff !important;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td{
border-right: 1px solid #ffffff !important;
padding-left: 24px !important;
}

.ant-pagination-item:not(.ant-pagination-item-active):not(.ant-pagination-item-disabled) {
border-color: white !important;
}
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th{
padding-left: 24px !important;
}
.ant-pagination-item {
display: flex !important;
justify-content: center !important; 
align-items: center !important;
}
.ant-pagination-item-active a{
color: #830065 !important;
}
.ant-pagination-item-active{
border-color: #830065 !important;
}
.ant-pagination-item:hover a {
color: #830065 !important; 
}
#create a {
color: #830065;
}                    
.open-div{
color: white;
font-size: 12px;
font-family: 'Inter', sans-serif;
font-weight: 700;
height: 26px; 
width: 70px;
border-radius: 16px;
background-color: #006845;
display: flex;
justify-content: center;
align-items: center;
}
.cloes-div{
color: #64748B;
font-size: 14px;
font-weight: 700;
height: 26px; 
width: 63px;
border-radius: 16px;
background-color: #F1F5F9;
display: flex;
justify-content: center;
align-items: center;
}
.search-input > input{
padding-left: 5px !important;

}
.search-input > .ant-input::placeholder {
color: #64748B !important;
font-size: 16px !important;
font-weight: 400 !important;
opacity: 1 !important;
}
.custom-table .ant-table-body::-webkit-scrollbar {
display: none;
}
.ant-spin-spinning{
position: none !important;
margin: 0px !important;
}
.mood-header{
font-size: 14px;
font-weight: 700;
color: #64748B;
}

.mood-item {
display: flex;
flex-direction: column;
align-items: center;
flex: 1 1 calc(25% - 5px);
box-sizing: border-box;
}

.mood-item img {
margin-bottom: 10px;
width: 50px;
height: 50px;
}

.mood-date{
font-size: 16px;
font-weight: 400;
color: #830065;
margin-bottom: 8px;
}
.mood-day{
font-size: 10px;
font-weight: 700;
color: #0F172A;
}

.task-dashboard-content {
display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
background-color: white;
padding: 21px 63px 21px 43px;
box-sizing: border-box;
margin-top: 8px;
border-radius: 8px 8px 32px 8px;
}

.task-item {
display: flex;
flex-direction: column;
align-items: flex-start;
margin-bottom: 2px;
width: 100%;
}

.task-item img {
width: 100%;
max-width: 100%;
box-sizing: border-box;
}

.pending-title{
font-size: 14px;
font-weight: 600;
color: #64748B;
}
.status-update-div{
display: flex;
justify-content: space-between;
}
.task-header {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
margin-bottom: 10px;
}
.update-task-button {
  background: #830065 !important;
  border-color: #830065 !important;
  border-radius: 8px !important;
  height: 34px !important;
  width: 90px !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.update-task-button:disabled {
  color: #ffffff !important; 
  border-color: #94A3B8 !important;
  background: #94A3B8 !important;
}
.task-title{
font-size: 10px;
font-weight: 600;
color: #0F172A;
}
.appoint-dashboard-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  padding: 20px; 
  box-sizing: border-box;
  margin-top: 24px;
  border-radius: 8px 8px 32px 8px;
}

.up-header {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
margin-bottom: 10px;
}

.upcoming-appoint-text {
font-size: 14px;
font-weight: 700;
color: #64748B;
}

.up-appoint-card {
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 18px 14px 4px 14px;
  border-left: 4px solid rgba(131, 0, 101, 0.15);
  text-align: left;
  box-sizing: border-box;
  width: 280px;
  max-width: 100%;
  margin-bottom: 20px;
  flex: 1 1 100%;
}
.appoint-title {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #0F172A;
  margin: 2px 0;
}

.appoint-name {
font-family: 'Inter', sans-serif;  
font-size: 14px;
font-weight: 400;
color: #64748B;
margin: 4px 0;
}

.role-name {
  font-family: 'Inter', sans-serif;  
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin: 4px 0;
  margin-top: -20px;
  }

.appoint-date {
font-family: 'Inter', sans-serif;
font-size: 14px;
font-weight: 400;
color: #0F172A;
margin: 4px 0;
}

.appoint-time {
font-family: 'Inter', sans-serif; 
font-size: 14px;
font-weight: 400;
color: #0F172A;
margin-bottom: 30px;
}

.view-all-button{
  border-radius: 8px !important;
  height: 35px !important;
  width: 94px !important;
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #fff !important;
}

.task-status-title{
font-size: 18px;
font-weight: 700;
color: #0F172A;
}

.goal-content{ 
  margin-top: 20px;
  background: white;
  border-radius: 8px 8px 32px 8px;
  margin-bottom: 10px;
  padding: 30px;
  width: 100%; 
  max-width: 100%;
  box-sizing: border-box;
}

.goal-term-title{
font-size: 12px;
font-weight: 600;
color: #0F172A;
}
.term-title{
font-size: 14px;
font-weight: 400;
color: #0F172A;
margin: 0;
}
.vertical-flex-container {
display: flex;
flex-direction: column;
gap: 10px;
margin-bottom: 10px;
}

.horizontal-flex-item {
display: flex;
align-items: center;
}

.horizontal-flex-item img {
width: 14px;
height: 14px;
margin-right: 10px;
}
.term-divider {
height: 2px;
background-color: #f8daf9;
border: none;
margin: 20px 0;
}

.goal-content-incomplete{
background: white;
border-radius: 8px 8px 32px 8px;
margin-bottom: 10px;
padding: 30px;
width: 100%; 
max-width: 100%;
box-sizing: border-box;
}
.no-goal-text{
font-size: 16px;
font-weight: 400;
color: #475569;
}

.appointments-container {
display: flex;
justify-content: center;
align-items: center;
height: 100%;
}

.appointments-list {
width: 100%;
}

.no-appointments-container {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100%;
}

.no-appointments-image {
height: 48px;
width: 48px; 
margin-bottom: 10px;
}
  /* case view */
  .view-title{
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 10px;
    margin-top: 8px;
  }
  .add-case-div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 8px; 
    margin: 11px 0 0 2px;
    z-index: 1;
    cursor: pointer;
  }
  .case-number{
    font-size: 14px;
    font-weight: 700;
    color: #0F172A;
    margin-bottom: 24px;
  }
  .client-name{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
  }
  .status{
    font-size: 12px;
    font-weight: 700;
    color: white;
    margin: 0;
  }
  .case-card-lib {
    border-radius: 6px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06);
    background: #fff;
    padding: 22px;
    border-left: 4px solid #83006526;
    margin-right: 8px;
    text-align: center;
    height: 98px;
    position: relative; 
  }
  /* case view */

  .user-library-dashboard-content {
    padding: 30px;
    margin-top: 20px;
    background: white;
    border-radius: 8px 8px 32px 8px;
}

.user-library-card{
    width: 100%;
    height: 226px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 12px 0px;
}
.user-library-card-label{
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
    color: #0F172A;
}
.user-library-icon{
    height: 64px;
    width: 64px;
    margin-bottom: 30px;
}
.v-all-task{
  font-size: 16px;
  font-weight: 700;
  color: #830065;
  margin-top: 14px;
}
.up-appoint-text{
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 3px;
}
.last-row td {
  border-bottom: none !important;
}

.up-appoint-card-2 {
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 18px 14px 4px 14px;
  border-left: 4px solid rgba(131, 0, 101, 0.15);
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 100%;
  }
  .view-details{
    font-size: 14px;
    font-weight: 700;
    color: #830065;
    cursor: pointer;
  }
  .view-deallocate{
    display: flex;
    justify-content: space-between;
  }
  .name-2{
    font-size: 14px;
    font-weight: 400;
    color: #000;
    margin-bottom: 37px;
  }
  
  .custom-style {
    color: #94A3B8 !important;
    font-size: 18px !important;
}
.icon-2 {
  color: #830065;
  font-size: 18px;
}
.task-title-text{
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color:#0F172A;
  margin: 0;
}

.user-library-dashboard-content-2 {
  padding: 20px;
  margin-top: 20px;
  background: white;
  border-radius: 8px 8px 32px 8px;
}

.a-add-icon{
  height: 24px;
  width: 24px;
}
.created-title{
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 6px;
}

.date-title{
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}
.status-container {
  background: #006845;
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  color: #fff;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
}
.close-container{
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  background: #F1F5F9;
  border-radius: 16px;
  padding: 4px 8px 4px 8px;
  color: #64748B;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.goal-title {
  font-size: 16px;
  font-weight: 700;
  color: #0F172A;
  margin: 2px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    height: 50px !important ;
    border-radius: 8px !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    margin-top: 8px !important; 
  }

  .ant-collapse{
    margin-bottom: 0 !important;
  }
  .long-goal-title {
    font-size: 16px;
    font-weight: 700;
    color: #0F172A;
    margin-right: 10px;
    margin-top: 14px;
    }
    .no-long-goal-title {
      font-size: 14px;
      font-weight: 600;
      color: #0F172A;
      margin-left: 16px;
      margin-top: 20px;
      }

    .ant-select.ant-select-in-form-item.ant-select-auto-complete.font-style-auto.ant-select-single.ant-select-show-search {
        height: 52px !important;
        line-height: 52px !important; 
    }

    .font-style-auto {
      color: #0F172A !important;
      font-size: 16px !important;
      text-transform: capitalize !important;
      font-weight: 400 !important;
    }
.no-medium-goal-title {
  font-size: 14px;
  font-weight: 600;
  color: #0F172A;
  margin-top: 20px;
  }
.reassess-div {
  background-color: #FF9595; 
  color: #ffffff; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 30;
  width: 94;
  border-radius: 16px;
  font-weight: 400;
  font-size: 12;
}
.approval-div {
  background-color: #FEF3C7; 
  color: #FBB03F; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 30;
  width: 130;
  border-radius: 16px;
  font-weight: 400;
  font-size: 12;
}

.add-appointment-container {
  display: flex;
  margin-top: 10px;
  cursor: pointer;
}

.client-content {
  margin-top: 32px;
  background: white;
  border-radius: 8px 8px 32px 8px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}
.client-content2 {
  margin-top: 42px;
  background: white;
  border-radius: 8px 8px 32px 8px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.task-dashboard-content,
.appoint-dashboard-content {
  margin-top: 20px;
}

.task-item,
.up-appoint-card {
  margin-bottom: 10px;
}

#case-id {
  margin-bottom: 20px; 
}


@supports (display: grid) {
  .mood-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 5px;
  }
}


@media (max-width: 768px) {
  .task-status-title {
    font-size: 16px; 
  }
}


@media (max-width: 480px) {
  .task-status-title {
    font-size: 14px; 
  }
}

@media (min-width: 1200px) and (max-width: 1464px) {
  .view-details {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .view-details {
    font-size: 10px;
  }
}
@media (max-width: 360px) {
  .view-details {
    font-size: 8px;
  }
}
@media (min-width: 576px) and (max-width: 690px) {
  .view-details {
    font-size: 10px;
  }
}
.status-edit-container {
  display: flex;
  align-items: center;
}

@media (max-width: 405px) {
  .status-edit-container {
    display: block;
  }

  .edit-button {
    margin-top: 8px;
  }
}
@media (max-width: 500px) {
  .up-appoint-text{
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .header-container {
    padding: 5px;
  }

  .a-add-icon {
    width: 12px !important;
    height: 12px !important;
    margin-right: -2px !important;
    margin-top: 2px !important;
  }

  .header-item3 {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .task-title-text{
    font-size: 14px;
  }
}
.view-label-text{
  font-size: 24px;
  color: #000000;
  font-weight: 700
}
@media (max-width: 440px) {
  .up-appoint-card-2 {
    width: 100% ;
  }
  .view-label-text{
    font-size: 18px;
    color: #000000;
    font-weight: 700
  }
}

.MuiPaper-elevation1{
  box-shadow: none !important;
}


