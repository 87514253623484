.dashboard-content {
  padding: 48px;
  margin-top: 62px;
  background: white;
}


/* cases */
.create-case-div {
  position: absolute;
  top: 90;
  right: 40;
  margin-right: 24px;
  display: flex;
  align-items: center;
}

.bodytext-1 {
  font-size: 18px;
  font-weight: 700;
  color: #830065;
  margin-left: 6px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.create-case-content {
  padding: 48px;
  position: relative;
  margin-top: 40px;
  background: white;
  width: 100%;
  border-radius: 10px;
}

.headline-3 {
  font-size: 14px;
  font-weight: 700;
  color: #64748B;
  margin-left: 8px;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif !important;
}

.ant-input-affix-wrapper {
  font-size: 16px !important;
  font-weight: 700px !important;
  color: #334155 !important;
  font-family: 'Inter', sans-serif !important;
}
.ant-input-affix-wrapper::placeholder{
  font-size: 16px !important;
  font-weight: 700px !important;
  color: #94A3B8 !important;
  font-family: 'Inter', sans-serif !important;
}
.case-title {
  color: #0F172A;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-top: 14px;
}
.client-name {
  color:#0F172A;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin-top: 4px;
}
.input-search {
  font-family: 'Inter', sans-serif;
}
.font-style {
  font-family: 'Inter', sans-serif;
}

.font-style1 {
  font-family: 'Inter', sans-serif;
  margin-left: 8px;
}
.font-style2 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color:"#0F172A";
  font-weight: bold;
}
.font-style3 {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1)
}

.ant-table-thead .ant-table-cell {
  border-bottom: 1px solid #E2E8F0 !important;
  background: #F1F5F9 !important;
}

.case-content {
  margin-top: 40px;
  background: white;
  border-radius: 8px 8px 32px 8px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.case-search-div {
  padding: 28px 14px 28px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view-text {
  color: #830065;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.unique-ref-text {
  color: #334155;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.name-text {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.appointment-name-text {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.custom-table {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 32px !important;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
  border-right: 1px solid #ffffff !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td {
  border-right: 1px solid #ffffff !important;
}

.ant-pagination-item:not(.ant-pagination-item-active):not(.ant-pagination-item-disabled) {
  border-color: white !important;
}

.ant-pagination-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-pagination-item-active a {
  color: #830065 !important;
}

.ant-pagination-item-active {
  border-color: #830065 !important;
}

.ant-pagination-item:hover a {
  color: #830065 !important;
}

.header-container {
  margin-top: 24px;
  cursor: pointer;
}

.header-container1 {
  display: flex;
  justify-content: space-between;
}
.header-container3 {
  display: flex;
  margin-top: 24px;
  cursor: pointer;
  margin-bottom: 10px;
}
.header-item {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-weight: bold;
}
.header-item1 {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-weight: bold;
  margin-left: 8px;
  font-size: 14px;
}
.header-item2 {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-weight: bold;
  font-size: 12px;
  margin-right: 10px;
}
.header-item3 {
  color: #830065;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-weight: bold;
  margin-left: 8px;
  font-size: 16px;
}
.carousal{
  height: 183px;
}
.carousal2{
  height: 150px;
}

.client-text {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.button__badge {
  margin-left: 8px;
  background-color: #830065;
  border-radius: 25px;
  color: white;
  padding: 1px 1px;
  font-size: 12px;
  font-family: 'Inter', sans-serif;
}

.add-icon {
  height: 24px;
  width: 24px;
}

.list-item {
  background: white;
  margin: 10px 10px 10px 0;
  padding: 16px;
  border: 4px outset #e9cfe3;
  border-radius: 6px;
  border-right: none;
  border-top: none;
  border-bottom: none;

  -moz-box-shadow: 0 3px 10px rgb(0 0 0 / 0.06);
  -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.06);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.list-itemEmpty {
  background: white;
  margin: 10px 10px 10px 0;
  border: 4px outset #e9cfe3;
  border-radius: 6px;
  border-right: none;
  border-top: none;
  border-bottom: none;
  display:flex;
  justify-content:center;
  align-items:center; 
  height:121px;
  width:236px;

  -moz-box-shadow: 0 3px 10px rgb(0 0 0 / 0.06);
  -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.06);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.appointment-modal .ant-modal-close {
  top: 30px !important;
  color: #334155 !important;
}

.appointment-modal {
  top: 50px !important;
}

.apt-design .ant-select-selector {
  height: 51px !important;
  align-items: center;
  border-radius: 6px !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85) !important;
}

.bold-label {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #334155 !important;
  font-family: 'Inter', sans-serif !important;
}

.time-design {
  width: 100% !important;
  height: 51px;
  align-items: center;
  border-radius: 6px !important;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85) !important;
  cursor:pointer;
	cursor:hand;
}


  
.ant-picker-cell-inner{
  width:40px !important;
  height:40px !important;
  font-size:14px !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none !important;
}
.image1 {
  cursor:pointer;
	cursor:hand;
  color:#830065;
  font-size: 14px;
  font-weight: bold;
}
.image {
  cursor:pointer;
}
.previmage {
  cursor:pointer;
  margin-left: 20px !important;
}
.nextimage {
  cursor:pointer;
  margin-right: 28px !important;
}
.react-calendar__navigation button:hover{
  background-color: transparent !important;
}
.react-calendar__navigation button:focus{
  background-color: transparent !important;
}
.ant-picker-input #date,.ant-picker-input #start-time,.ant-picker-input #end-time{
  cursor:pointer;
	cursor:hand;
  font-size: 0.8rem !important;
  font-weight: 700px !important;
  color: #334155 !important;
  font-family: 'Inter', sans-serif !important;
}
.ant-select-selector .ant-select-selection-item {
  font-size: 0.9rem !important;
  font-weight: 700px !important;
  color: #334155 !important;
}
.ant-picker-input #date::placeholder,.ant-picker-input #start-time::placeholder,.ant-picker-input #end-time::placeholder{
  font-size: 16px !important;
  font-weight: 700px !important;
  color: #94A3B8 !important;
  font-family: 'Inter', sans-serif !important;
}
.ant-select-selection-placeholder {
  font-size: 16px !important;
  font-weight: 400px !important;
  color: #c9d2e0 !important;
  font-family: 'Inter', sans-serif !important
}
.date{
  color:#000;
  font-size: 14px !important;
  font-weight: 400 !important;
}


/* calender - css */

.react-calendar__navigation__label span {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #0F172A !important;
  font-family: 'Inter', sans-serif !important;
}

.apt-calender .react-calendar{
  padding: 30px;
  width: 100%;
}
.apt-highlight{
  font-size:18px;
  font-weight:700;
  padding:0;
  line-height:0;
  color: #830065 !important;
}
.title-scheduler {
  font-size:20px;
  font-weight:700;
  font-family: 'Inter', sans-serif !important;
}
.apt-scheduler{
  padding: 20px;
  background: white;
  border-radius: 8px 8px 32px 8px;
}
.timingsRow{
  border:"1px";
  border-top-style: groove;
  border-width: thin;
  border-color:#e2e0e2;
  min-height:60px !important;
}
.time-row{
  min-height:60px !important;
}
.text-event {
  font-family: 'Inter', sans-serif ;
  font-size:13px;
  font-weight:bold;
  color:'#0F172A';
  cursor:pointer;
	cursor:hand;
}
.event-container {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  padding-Top:10px;
}
.col-container {
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}
.col-text{
  font-family: 'Inter', sans-serif ;
  font-size:12px;
  color:'#334155';
  margin-left: 5px;
}
.time-rowtext{
  font-family: 'Inter', sans-serif ;
  font-size:12px;
  color:#334155;
}
.border-right {
  border-right: 2px solid #FBF1F9;
}
.dualcol-test {
  background: linear-gradient(to right, #830065 0%, #830065 1%, #FBF1F9  1%, #FBF1F9 100%);
  display:flex;
  align-items:center;
  z-index:5000;
}
.apt-evt-title{
  margin-left:20px;
  position: absolute;
  z-index: 9;
}
.view-close-btn{
  margin-left:auto;
  margin-right:10px;
  order:1;
  z-index: 5;
  align-self:center;
}
.dualcol-event{
  background-image:linear-gradient(180deg, #f0efef 0% 0%, #FDF8FC 16% 100%);
  -moz-box-shadow: 0 3px 10px -4px rgb(0 0 0 / 0.06);
  -webkit-box-shadow: 0 3px 10px -4px rgb(0 0 0 / 0.06);
  box-shadow: 0 3px 10px -4px rgb(0 0 0 / 0.2);
  margin-Left:10px;
  margin-right:15px;
  margin-bottom: 5px;
  display:none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.filter-event{
  margin-top: 20px;
  background-color: #ffffff !important;
  border-radius: 8px 8px 32px 8px;
  padding:20px;
  margin-bottom: 20px;
}
.calender-back{
  border-radius: 8px 8px 32px 8px;
  border: 1px solid red;
}
.filter-text {
  color: #0F172A;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.dualcol-event.show-apt-details{
  display: block;
}

.delete-modal .ant-modal-close{
	top: 15px !important;
	color: #334155 !important;
}
.filter-event .ant-checkbox-inner{
  border-radius: 50% !important;
}
.filter-event .filter-checkbox{
  margin-top: 10px;
}

  .react-calendar__tile{
    flex: 0 0 9.286% !important;
    border-radius: 50% !important;
  }

  .calendar__tile--active abbr{
    color:white !important;
    font-weight: 700;
  }
  
  .react-calendar__tile--now {
    background-color: #fff !important;
    color: #830065;
  }
  
  .react-calendar__tile--active{
    background-color: #830065 !important;
    text-align: center;
  }
  
  .react-calendar__tile--active abbr{
    color: white !important;
    font-weight: 700;
  }
  
  .react-calendar__tile--active .apt-highlight{
    color: #fff !important;
  
  }
  
  .react-calendar__month-view__days__day--weekend{
    color:#0F172A !important;
    font-size: 14px;
  
  }
  .apt-calender .react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--neighboringMonth){
    font-size: 14px !important;
    font-weight: 400px !important;
    color: #0F172A;
    font-family: 'Inter', sans-serif !important;
  }
  .apt-calender .react-calendar__tile--now {
    background: #ffffff ;
    font-weight: 400;
    font-weight: bold;
  }
  
  .react-calendar__navigation__label{
    pointer-events:none;
  }
  .react-calendar__month-view__days{
    padding: 8px 10px 8px 20px ;
    margin-left: 5px;
  }
  .apt-calender .react-calendar button{
    margin: 0px 1rem;
  }
  .apt-calender .react-calendar__month-view__weekdays__weekday{
  color: #64748B;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
  font-family: 'Inter', sans-serif !important;
 
}

.react-calendar__month-view__weekdays{
  color: #64748B;
  padding:0px 20px !important;
  margin-left: 17px !important
}
.react-calendar__month-view__weekdays__weekday{
  flex: 0 0 13.5% !important;
  
}
.react-calendar__month-view__weekdays__weekday abbr{
  text-decoration: none !important;
}
@media screen and  (max-width : 1800px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 14% !important;
  }
 
}
@media screen and  (max-width : 1650px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 15% !important;
  }

}
@media screen and  (max-width : 1550px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 14% !important;
    padding: 0px;
  }
  .react-calendar__tile{
    flex: 0 0 14% !important;
    border-radius: 50% !important;
  }

 .apt-calender .react-calendar button{
  margin: 0px;
}
}
@media screen and  (max-width : 1390px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  
  }
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 14% !important;
    padding: 0px;
  }
  .react-calendar__tile{
    flex: 0 0 14% !important;
    border-radius: 50% !important;
  }
 .apt-calender .react-calendar button{
  margin: 0px;
}

}
@media screen and  (max-width : 1250px) {

  .react-calendar__month-view__weekdays{
    padding:0px auto !important;
  }
  
  .react-calendar__month-view__weekdays__weekday{
    flex: 0 0 13% !important;
    padding: 0px !important;
  }
  .react-calendar__tile{
    flex: 0 0 13% !important;
    border-radius: 50% !important;
  }
 .apt-calender .react-calendar button{
  margin: 0px;
}
.apt-calender .react-calendar{
  padding: 5px !important;
  border-radius: 0px 0px 24px 0px !important;
}
}
.clientheadContainer{
  display: flex;
}
.ant-spin-spinning{
  position: none !important;
  margin: 0px !important;

}
.react-calendar__century-view__decades__decade--neighboringCentury:disabled{
  color:#cdcdcd !important;
  background-color: none !important;

}
.react-calendar__tile:disabled{
  color:#cdcdcd !important;
  background-color: white !important;
}
.react-calendar__month-view__days__day--neighboringMonth{
  font-size: 14px !important;
  color:#94A3B8 !important;
  font-weight: 400 !important;
}
.react-calendar__month-view__days__day--neighboringMonth .react-calendar__month-view__days__day--weekend{
  font-size: 14px !important;
  color:#94A3B8 !important;
  font-weight: 300 !important;
}
.title-heading{
  color: #000000 !important;
  font-size: 24px !important;
  font-weight: 700 !important;

}
.App-cancel-btn-style{
  height: 32px !important;
  width: 108px !important;
  color: #830065 !important;
  font-weight: 700 !important;
  font-size: 16px !important;
	background: #ffff !important;
  border: none;
  outline: none;
  border-color: #ffff !important;
	margin-right: 16px !important;
  box-shadow: none !important;
}

.App-submit-heading{
  height: 32px !important;
  width: 108px !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  background: #830065 !important;
  border-color: #830065 !important;
  border-radius: 8px !important;
}
.APP-title-heading{
  color: #64748B !important;
  font-size: 14px !important;
  font-weight: 700 !important;

}

.cardText{
  color:#94A3B8;
  font-size:12px;
  font-weight:400;
  margin-top:1px
}

.cardInternal{
  height:100%;
  width:100%;
  display:flex;
  flex-direction:column;
	justify-content:center;
  align-items:center;
}
.header-itemEmpty {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-weight: bold;
  margin-left: 8px;
  font-size: 14px;
  text-transform: capitalize;
}
.spinEmpty{
  height: 100vh;
  width: 100%;
	display:flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  text-align: center;
	position:relative;
	background-color:transparent;
}
.devider{
  width: 100%;
  margin: 0;
}
.iconContainer{
  display: flex;
  justify-content: space-between;
}
.editButton{
  margin-left: 8px;
}
.rowStyle{
  margin-top: 20px;
}
.btnContainerapmnt{
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-end;
}

@media screen and (max-width: 500px) {
  .header-container {
    padding: 5px;
  }

  .header-item2 {
    font-size: 11px;
  }
  .font-style2 {
    font-size: 14px;
  }
  .name-style {
    font-size: 14px;
  }


  .font-style1 {
    font-size: 8px; 
    margin-left: 2px;
  }
}
@media screen and (max-width: 500px) {
  .header-container {
    padding: 5px;
  }

  .add-icon {
    width: 14px !important;
    height: 14px !important;
    margin-right: -2px !important;
    margin-top: 2px !important;
  }

  .header-item3 {
    font-size: 12px;
  }
  .font-style2 {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .title-scheduler{
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .title-scheduler{
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .time-rowtext{
    font-size: 10px;
  }
}

@media (max-width: 400px) {
  .ant-badge-count{
    margin-top: -4px;
  }
}

.name-style{
  font-size: 14px;
	color: #64748B;
}
.apt-calender{
  margin-right: 30px;
}
@media (max-width: 992px) {
  .apt-calender{
    margin-right: 0px;
  }
}
