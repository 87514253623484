/*Customizable Area Start*/
.centered-content-signup {
	text-align: center;
	background: rgba(251,241,249,255);
}
.centered-content-right {
	text-align: center;
	max-width: 430px;
}
.controller-cookies{
	font-size: 16px
}
.save-button-c{
	height: 56px !important;
	width: 255px !important;
	background: #830065 !important;
	border-color: #830065 !important;
	border-radius: 8px !important;
	color: white !important;
	font-size: 16px !important;
	font-weight: 700 !important;
	position: absolute !important;
	bottom: 10px !important;
	right: 37px !important;
  }
@media (max-width: 450px) {
	.hi-there{
		font-size: 16px;
	} 
	.hi-there1 {
	  padding: 0 20px 0 20px; 
	}
	.cookies-header{
		font-size: 14px;
	}
	.cookies-setting-header{
		font-size: 12px !important;
	}
	.controller-cookies{
		font-size: 14px !important;
	}
	.policy-content{
		font-size: 14px !important;
	}
	.policy-content-2{
		font-size: 14px !important;
	}
	.policy-content-3{
		font-size: 14px !important;
	}
	.policy-content-4{
		font-size: 14px !important;
	}
	.panel-header{
		font-size: 14px !important;
	}
	
  }
  @media (max-width: 600px) {
	.save-button-c{
		height: 30px !important;
		width: 180px !important;
		right: 8px !important;
		font-size: 14px !important;
	}
  }

 
.hi-there1{
	font-size: 18px;
	margin-top: 0;
	margin-bottom: 0px;
}
.hi-there {
	font-size: 18;
	font-weight: 400;
	color: #475569;
	margin: 0;
	
}
.centered-content-right2 {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.headline-1 {
	font-size: 36;
	font-weight: 700;
	color: #0f172a;
	margin: 0;
}
.headline-2 {
	font-size: 24;
	font-weight: 700;
	color: #0f172a;
	height: 26px;
	margin-top: 40px;
	margin-bottom:20px

}
.otp-headline-2 {
	font-size: 24;
	font-weight: 700;
	color: #0f172a;
	margin-bottom: 0;
} 
.bodytext-2 {
	font-size: 18;
	font-weight: 700;
	color: #475569;
	margin: 0;
}
.bodytext-3 {
	font-size: 16;
	font-weight: 400;
	color: #334155;
	margin-bottom: 0;
}

.back {
	font-weight:'400';
	font-size: 16px;
	color: #0F172A;
	margin-left: 6px;
	margin-bottom: 0;
}
.ant-input {
	border-radius: 8px !important; 
	padding-left: 8px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-right: 12px !important;
	font-size: 16 !important;
	font-weight: 400 !important;
}
.ant-form-item-explain-error {
    color: #DC2626 !important;
    text-align: left !important;
	font-weight: 400 !important;
	font-size: 12px !important;
}

.ant-input-password {
	border-radius: 8px !important;
	padding-left: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	padding-right: 12px !important;
}
.bold-label .ant-form-item-label label {
	font-size: 16px;
	font-weight: 700;
	color: #334155;
	text-transform: capitalize;
}
.disable-label .ant-form-item-label label{
	font-size: 16px;
	font-weight: 700;
	color: #9da4ad;
	text-transform: capitalize;
}
.fullname{
	text-transform: capitalize !important;
}
.center-header{
	font-size: 24;
	font-weight: 700;
	color: #0f172a;
	text-align: center;
	padding: 22px;
}
.toolip{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: -1px 0 5px 3px;
	
}
ul {
	list-style-type: disc;
}
  
li {
	color: black;
	padding: 5px;
}
.ant-tooltip-inner {
    min-width: 400px;
	border-radius: 4% !important;
}
.ant-toolip{
	max-width: 400px;
	
}

.otp-container {
	display: flex;
	gap: 24px;
	margin-top: 25px;
	margin-bottom: 5px;
}
  
.otp-input {
	font-weight: 600;
	width: 56px;
	height: 56px;
	text-align: center;
	font-size: 18px;
	border-radius: 8px;
	border: 1px solid #cbd5e1;
}
.otp-input-error {
	font-weight: 600;
	width: 56px;
	height: 56px;
	text-align: center;
	font-size: 18px;
	border-radius: 8px;
	border: 1px solid red;
	color: #DC2626 !important;
}
.otp-section{
	text-align: center;
	max-width: 423px;
	margin: 260px auto !important;
}
.timer {
    color: #334155;
    font-size: 16px;
    font-weight: 400;
	text-align: center;
	margin-right: 10;


}
.otp-centered-content {
	padding: 100px;
	text-align: center;
	background: rgb(248 240 245);
	height: 1035px;
}
.ant-form-item {
    margin: 0 0 8px !important;
}
.ant-input-disabled{
	color: #878b94 !important;
	font-weight: 400 !important;
	background-color: white !important;
	font-size: 16px;
}
.cookies-header{
	font-size: 24px;
	font-weight: 700;
	text-align: left;
	padding: 24px 0px 11px 26px;
}
.cookies-modal .ant-modal-close{
	top: 30px !important;
	color: #334155 !important;
}


.go-cookies{
	color: #830065;
	font-size: 16px;
	font-weight: 700;
}

.policy-title{
	font-size: 14px;
	font-weight: 700;
	color: #64748B;
	margin: 33px 17px 32px 13px;
}
.policy-content{
	font-size: 16px;
	font-weight: 400;
	color: #0F172A;
	margin: 20px 17px 100px 13px;
}
.policy-content-2{
	font-size: 16px;
	font-weight: 400;
	color: #0F172A;
	margin: 20px 18px 30px 13px;
}
.policy-content-3{
	font-size: 16px;
	font-weight: 400;
	color: #0F172A;
	margin: 0px 40px 0px 13px
}
.policy-content-4{
	font-size: 16px;
	font-weight: 400;
	color: #0F172A;
	margin: 8px 40px 30px 13px;
}
.policy-left-div{
	border-bottom-right-radius: 64px;
	box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}
.tnc-error-div{
	display: flex;
	position: relative;
   	bottom: 100px; 
	width: 496;
    padding: 7px;
    text-align: center;
	border-radius: 5px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); 
  	background: #fff; 
	justify-content: center;
	margin-top: -20px;
}
.otp-edit-img{
	height: 18px;
	width: 18px;
	margin-left: 6px;
	margin-bottom: 5px;
}
.ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #fffdfd !important;
	margin-bottom: 0px !important;
}
.ant-collapse {
	border: 1px solid #fffdfd !important;
	margin-bottom: 76px !important;
	background-color: #ffffff;
}
.ant-collapse-content {
    border-top: 1px solid #fffdfd !important;
}
.panel-header{
	font-size: 18px;
	font-weight: 400;
}
.panel-content{
	font-size: 16px;
	font-weight: 400;
	margin-right: 40px;
}
.panel-content-2{
	font-size: 16px;
	font-weight: 400;
}
.ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
	margin-left: 13px !important;
}
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ant-switch {
	
	background-color:  rgba(131, 0, 101, 0.15)!important;
	min-width: 37px !important;
}
.ant-switch.ant-switch-checked {
    background-color: #830065 !important;
	min-width: 37px !important;
}
.left-header-title{
	display: flex;
	align-items: center;
	justify-content: start;
}
.back-image{
	height: 24px;
	width: 24px;
	margin-left: 22px;
	cursor: pointer;
}
.ant-modal-content {
	padding: 0 !important;
	border-radius: 8px 8px 32px 8px !important;
  }
  
  .ant-modal-body {
	padding: 0 !important;
  }
  
  .ant-modal-header {
	padding: 0 !important;
	background-color: none !important;
	border-radius: 6px !important;
  }
  
  .ant-modal-footer {
	padding: 0 !important;
  }
  .cookies-setting-header{
	font-size: 24px;
	font-weight: 700;
	margin: 24px 0px 24px 12px;
}
.signup-divider .ant-divider-horizontal {
	margin: 0px !important;
}
.privacy-modal .ant-modal-close{
	top: 22px !important;
	color: #334155 !important;
}
/*Confirm password error */
span.ant-input-password.ant-input-affix-wrapper-status-error #password.ant-input{
	color: #DC2626 !important;
}
.feature-content{
	font-size: 18;
	font-weight: 400;
	color: #475569;
	margin: 0;
}
.ant-input-affix-wrapper.specific-input2 {
    border-radius: 8px !important;
    padding: 1px !important;
}
.ant-input-password-icon.anticon {
    padding: 10px !important;
}
.spinContainer{
	position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.innerSpinContaine{
	display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 450px) {
	.headline-1{
		font-size: 20px;
	} 
}
/*Customizable Area End*/
