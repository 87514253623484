.contact-content {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    width: 100%;
    background-color: rgb(251, 241, 249);
    box-sizing: border-box;
    margin-top: 24px;
  }


.contact-card {
    border-radius: 6px 6px 30px 6px;
    box-shadow: 0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008;
    background: #fff;
    padding: 16px 12px 22px 20px; 
    text-align: left;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    max-width: 100%;
    }
    .role-type{
        font-size: 12px;
        font-weight: 700;
        color: #94A3B8;
    }
    .img-card{
        height: 76px;
        width: 76px;
        border-radius: 76px;
        margin-right: 10px;
    }
    .contact-name{
        font-size: 24px;
        font-weight: 700;
        color: #0F172A;
       
    }
    .contact-email{
        font-size: 14px;
        font-weight: 400;
        color: #830065;
    }
    .contact-phone{
        font-size: 14px;
        font-weight: 400;
        color: #0F172A;
    }
    .contact-card .contact-name,
    .contact-card .contact-email,
    .contact-card .contact-phone,
    .contact-card .role-type {
    margin: 0;
}
.contact-elements{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media screen and (max-width: 450px) {
    .margin-style {
        margin-left: 20px;
    }
}
