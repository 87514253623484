.header-container {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
  }
.header{
    display: flex;
    max-width: fit-content;
  }
.longtermbtn{
    display: flex;
    align-items: center;
  }
.header-item {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-weight: bold;
    text-transform: capitalize;
  }
  .header-item-Change{
    color: #830065;
    font-family: 'Inter', sans-serif;
    margin-left: 15px;
    font-size: 14px !important;
    cursor: pointer;
    font-weight: 700;
    text-transform: capitalize;
  }
 
.client-text {
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
  } 
.add-icon {
    height: 24px;
    width: 24px;
  }
.emptybox{
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} 
.emptyboximg{
  height: '88px';
  width: '93px';
  margin-bottom: '10px'
}
.search-content-main-goal{
  margin-top: 40px;
  background: white;
  border-radius: 8px 8px 32px 8px;
  padding: 24px 16px 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appointment-modal .ant-modal-close {
  top: 30px !important;
  color: #334155 !important;
}

.appointment-modal {
  top: 200px !important;
  height: 348px !important;
  width: 440px !important;


}
.bold-label {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #334155!important;
  font-family: 'Inter', sans-serif !important;
}
.header-container2 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px !important;
  margin: 0 !important;
  padding: 35px 24px 5px 40px !important;
}
.font-style {
  color: #94A3B8 !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
}
.input-Font-style {
  font-size: 16px !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
}
.image {
  cursor:pointer;
	cursor:hand;
}
.Goaltable{
  display: flex;
  flex-direction: column;
  border:none
}
.custom-table {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 32px !important;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
}
.ant-table-thead .ant-table-cell {
  border-bottom: 1px solid #E2E8F0 !important;
  background: #F1F5F9 !important;
}
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
  border-right: 1px solid #ffffff !important;
}

.ant-pagination-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}


.ant-pagination-item:hover a {
  color: #830065 !important;
}
.custom-table .ant-table {
  width: 100%; 
}
.goalNameHeading{
  font-size: 14px;
  font-weight: 700;
  margin-left: 10;
}
.goalheading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFF;
  padding: 0.8rem 1rem;
  border:0px transparent;
  outline:none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.innergoalheading{
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.goaltitle{
  color: #000;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 700;
}
.goalForm{
  padding: 20px 20px 30px 20px;
  width: 100%;
}
.formfooter{
  border-top:1px solid #E2E8F0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 32px 8px !important;  
}
.view-text {
  color: #830065;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
}
.selectBox{
  display: inline-flex;
  height: 40px ;
  width: 106px;
  margin-left: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 16px;
  background: #FEF3C7;
  color:#F59E08;
  border:none ;
}

.addtableicon {
  height: 22px;
  width: 22px;
  margin-left: 18px;
  cursor: pointer;
}
.status-completed {
  background-color: #006845;
  height: 26px;
  width: 81px;
  padding : 4px 8px 4px 8px !important; 
  border-radius: 16px;
  color: #FFF; 
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize !important;
}
.delete-modal{
  top: 200px !important;
  height: 252px !important;
  width:600px !important
}
.delete-modal .ant-modal-close{
	top: 15px !important;
	color: #334155 !important;
}
.trophyicon{
  width: 17px;
  height: 17.49px;
  flex-shrink: 0;
  margin-left: 5px;
}
.no-data-text{
  color: #475569;
  font-weight: 400;
  font-size:16px;
  padding: 10px;
}
.name-text2{
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
}
.longTerm-name-text{
  text-transform: capitalize;
  color:#334155;
  font-size: 12px;
  font-weight: 500;
}
.ClientGoalHeading{
  color: #0F172A;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 20px;
}                     
.name-text{
  font-size: 14px;
  font-weight: 500;
}         
.name-text-goalStatus{
  font-size: 14px;
  font-weight: 400;
}    

.input-wrapper {
  width: 360px;
  border-radius: 100px;
  overflow: hidden;
  border: 1px solid #CBD5E1;
}
.ant-select-selection-item {
  color: #0F172A !important; 
  font-size: 16px !important;
  font-weight: 700 !important;

}
.title-heading{
  color: #000000 !important;
  font-size: 24px !important;
  font-weight: 700 !important;

}
.cancel-btn-style{
  height: 50px !important;
  width: 120px !important;
  color: #830065 !important;
  font-weight: 700 !important;
  font-size: 16px !important;
	background: #ffff !important;
  border: none;
  outline: none;
  border-color: #ffff !important;
	margin-right: 16px !important;
  box-shadow: none !important;
}

.submit-heading{
  height: 50px !important;
  width: 120px !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  border: none;
  outline: none;
  box-shadow: none !important;
  background: #830065 !important;
  border-color: #830065 !important;
  border-radius: 8px !important;
}

.ant-select-item-option-content {
  border-bottom: 1px solid #ccc !important;
  width: 100% !important;
  padding: 10px !important;
}
.ant-select-dropdown-menu-item {
  padding: 0 !important;
}

.ant-select-dropdown{
  padding: 0px !important;
  margin: 0px !important;
}
.ant-modal-content{
  overflow: visible !important;
}

.ant-select-item-option{
  padding: 0 !important;
  margin: 0 !important;
}
.tablestatus{
  margin-left: 5px;
}
.ant-pagination-item-active a{
  color:white !important
}
.ant-pagination-item-active{
  background-color: #830065 !important;
  border-radius: 50% !important;
  border:none;
  transition: none !important;
}

.ant-pagination-item-active:hover a {
  color: white !important;
}

.ant-pagination-item:not(.ant-pagination-item-active):not(.ant-pagination-item-disabled){
  border: 1px solid rgb(201, 196, 196)  !important;
  border-radius: 50%;
}
.ant-pagination-prev:hover .ant-pagination-item-link{
  color:#830065 !important;
  border-color: #830065 !important;
  border-radius: 50% !important;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link{
  border-radius: 50% !important;
}

.ant-pagination-next:hover .ant-pagination-item-link{
  color:#830065 !important;
  border-color: #830065 !important;
}
.ant-pagination-prev {
  border-radius: 50% !important;
  border: 1px solid #ccc !important;
}

.ant-pagination-prev .ant-pagination-item-link{
  border:  none !important;
}
.ant-pagination-next {
  border-radius: 50% !important;
  border: 1px solid #ccc !important;
}

.ant-table-pagination.ant-pagination{
  margin:16px 20px !important;
}
.ant-pagination-next .ant-pagination-item-link{
  border-radius: 50% !important;
  border:  none !important;
}


.LongselectBox {
  appearance: none; 
  -webkit-appearance: none; 
  width: 81px !important;
  height: 26px !important;
  background-color: #FEF3C7 !important;
  color:#F59E0B  !important;
  Padding: 4px, 6px, 4px, 8px !important;
  border-radius: 16px !important;
}

.LongselectBox .ant-select-selector {
  border-radius: 16px !important; 
  background-color: #FEF3C7 !important;
  color: #f59e0b !important;

}
.LongselectBox .ant-select-focused .ant-select-selector {
  border: none !important;
  outline: none !important;
}


.clintdropdown{
  display: flex ;
  }
  
.CustomSelectBox .ant-select-selector {
  border-radius: 20px !important;
  background-color: #FEF3C7 !important;
  color: #f59e0b !important;
  padding-left: 30px; 
}

.CustomSelectBox .ant-select-focused .ant-select-selector {
  border: none !important;
  outline: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color:none!important;
  height: 100% !important;
  border-radius: 8px !important;

}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 22px !important;
  padding: 0 !important;

}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  padding: 11px !important;
}

.btnContainer{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.divider{
  padding:0px !important;
  margin: 0 0 20px 0 !important;
}
.goalText{
margin-left: 3px;
margin-top: 10px;
font-size: 16px;
font-weight: 700;
color:#830065
}

.MuiOutlinedInput-input{
  border-radius: 16px !important;
  width: 85px !important; 
  height: 27px !important;
  box-sizing: border-box !important;
  padding:5px 8px 5px 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color:#F59E0B !important;
  background-color: #FEF3C7 !important;
  border:none!important;
  outline:none !important



}
.MuiOutlinedInput-root{
  border-radius: 16px !important;
}
.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-formControl{
  width: 81px !important; 
  height: 27px !important;
  padding:5px 8px 5px 8px !important;

}
.ant-table-tbody > tr > td {
  width:250px !important
}

.MuiSelect-icon {
  fill: #F59E0B !important; 
}
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #FEF3C7!important;
}
@media (max-width: 400px) {
  .case-title{
    font-size: 14px;
    white-space: "nowrap";
    text-wrap: "nowrap";
  }
.innergoalheading{
  font-size: 8px !important;
}

}

